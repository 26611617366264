<template>
  <v-container>
    <v-row class="mb-1">
      <h3 class="text">Usuwanie konta</h3>
    </v-row>
    <v-row>
      <p class="text-sm grey--text">
        Usunięcie konta jest nieodwracalne i w jego efekcie wszystkie Twoje dane zostaną usunięte. Z
        powodów prawnych, niektóre dane mogą zostać przez nas zachowane.
      </p>
    </v-row>
    <v-row>
      <primary-button color="error-primary" :onClick="openDeleteModal">Usuń konto</primary-button>
    </v-row>
    <confirm-modal
      :open="openModal"
      title="Usuwanie konta"
      agree="Usuń konto"
      disagree="Anuluj"
      color="error-primary"
      v-on:decline="decline"
      v-on:accept="accept"
      :loading="loading"
      >Czy na pewno chcesz usunąć swoje konto? Usunięcie konta jest nieodwracalne i w jego efekcie
      wszystkie Twoje dane zostaną usunięte. Z powodów prawnych, niektóre dane mogą zostać przez nas
      zachowane.</confirm-modal
    >
  </v-container>
</template>
<script>
import PrimaryButton from './buttons/PrimaryButton.vue';
import ConfirmModal from './modals/ConfirmModal.vue';
import { userDataComputed } from '../store/helper';

export default {
  components: { PrimaryButton, ConfirmModal },
  name: 'DeleteAccount',
  data() {
    return {
      openModal: false,
      loading: false,
    };
  },
  methods: {
    openDeleteModal() {
      this.openModal = true;
    },
    async accept() {
      try {
        this.loading = true;

        this.$store.dispatch('auth/deleteAccount');

        this.openModal = false;
        this.$toasted.global.success({
          message: 'Usunięcie konta przebiegło pomyślnie',
        });
      } catch (err) {
        this.$toasted.global.error({
          message: 'Usunięcie konta nie powiodło się',
        });
        console.error(err);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    decline() {
      this.openModal = false;
    },
  },
  computed: {
    ...userDataComputed,
  },
};
</script>
<style lang="scss" scoped></style>
