import coOpFileTypes from '../static/coOpFileTypes';

const isValidZipCode = (code) => {
  return /^\d{2}-\d{3}$/.test(code);
};

const isEmpty = (value) => {
  return value === undefined || value === null || value === '';
};

const checkIfNumber = (value) => {
  if (isEmpty(value)) {
    return true;
  }
  return /^\d+$/.test(value);
};

export const isValidNip = (nip) => {
  if (nip === '') return true;
  if (typeof nip !== 'string') return false;

  // eslint-disable-next-line
  nip = nip.replace(/[\ \-]/gi, '');

  let weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
  let sum = 0;
  let controlNumber = parseInt(nip.substring(9, 10));
  let weightCount = weight.length;
  for (let i = 0; i < weightCount; i++) {
    sum += parseInt(nip.substr(i, 1)) * weight[i];
  }

  return sum % 11 === controlNumber;
};

export const isValidRowNumber = (rowNumber) => {
  if (parseFloat(rowNumber) > 30 || parseFloat(rowNumber) < 1) return false;
  if (!Number.isInteger(parseFloat(rowNumber))) return false;
  return true;
};

export const checkFileSize = (fileSize) => {
  const FILE_SIZE_LIMIT = 1024 * 1024 * 5;
  return fileSize <= FILE_SIZE_LIMIT;
};

export const cooperationAgreement = (name) => {
  const fileArr = name.split('.');
  return coOpFileTypes.includes(fileArr[fileArr.length - 1]);
};

export const validators = {
  passwordRules: [
    (v) => {
      return !!v || 'Pole jest wymagane';
    },
    (v) => (v && v.length >= 8) || 'Musi zawierać przynajmniej 8 znaków',
    (v) => /(?=.*[A-Z])/.test(v) || 'Musi zawierać przynajmniej jedną dużą litere',
    (v) => /(?=.*\d)/.test(v) || 'Musi zawierać przynajmniej jedną cyfrę',
    (v) =>
      /([!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/.test(v) ||
      'Musi zawierać przynajmniej jeden znak specjalny',
    (v) => /(?=.*[a-z])/.test(v) || 'Musi zawierać przynajmniej jedną małą litere',
  ],
  emailRules: [
    (v) => !!v || 'Pole jest wymagane',
    (value) => {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || 'Niepoprawny email';
    },
  ],
  nipRules: [
    (value) => {
      return isValidNip(value) || 'Niepoprawny NIP';
    },
  ],
  nipRequiredRules: [
    (value) => !!value || 'Pole jest wymagane',
    (value) => {
      return isValidNip(value) || 'Niepoprawny NIP';
    },
  ],
  zipCodeRules: [
    (v) => !!v || 'Pole jest wymagane',
    (value) => {
      return isValidZipCode(value) || 'Niepoprawny kod pocztowy';
    },
  ],
  shortDescRules: [(v) => v?.length <= 150 || 'Maksymalnie 150 znaków'],
  shortDescRequiredRules: [
    (v) => !!v || 'Pole jest wymagane',
    (v) => v?.length <= 150 || 'Maksymalnie 150 znaków',
  ],
  longDescRules: [(v) => v?.length <= 1000 || 'Maksymalnie 1000 znaków'],
  declaredPowerRules: [
    (value) => {
      if (value === '') return true;
      const parsedValue = parseFloat(value) > 0;
      return parsedValue || 'Niepoprawna deklarowana moc';
    },
  ],
  rowsNumberRules: [
    (v) => !!v || 'Pole jest wymagane',
    (v) => {
      const parsedValue = parseFloat(v) > 0 && parseFloat(v) <= 30;
      return parsedValue || 'Liczba rzędów powinna mieścić się w zakresie 1-30';
    },
    (v) => {
      const parsedValue = Number.isInteger(parseFloat(v));
      return parsedValue || 'Liczba rzędów powinna być liczbą całkowitą';
    },
  ],
  modulesNumberRules: [
    (v) => !!v || 'Pole jest wymagane',
    (v) => {
      const parsedValue = parseFloat(v) >= 0 && parseFloat(v) <= 30;
      return parsedValue || 'Liczba rzędów powinna mieścić się w zakresie 0-30';
    },
    (v) => {
      const parsedValue = Number.isInteger(parseFloat(v));
      return parsedValue || 'Liczba rzędów powinna być liczbą całkowitą';
    },
  ],
  deliveryRules: [
    (v) => !!v || 'Pole jest wymagane',
    (v) => {
      const parsedValue = parseFloat(v) >= 1 && parseFloat(v) <= 30;
      return parsedValue || 'Liczba dni powinna mieścić się w zakresie 1-30';
    },
    (v) => {
      const parsedValue = Number.isInteger(parseFloat(v));
      return parsedValue || 'Liczba rzędów powinna być liczbą całkowitą';
    },
  ],
  noRequiredZipCodeRules: [
    (value) => {
      let verified = false;
      if (value === '') {
        verified = true;
      } else if (isValidZipCode(value)) {
        verified = true;
      }
      return verified || 'Niepoprawny kod pocztowy';
    },
  ],
  requiredRules: [
    (value) => !!value || 'Pole jest wymagane',
    (value) => !!value.replace(/\s/g, '').length || 'Wartością nie mogą być same spacje',
  ],
  requiredSelectRules: [(value) => !!value || 'Pole jest wymagane'],
  attributeRules: [
    (value) => checkIfNumber(value) || 'Liczba jest wymagana',
    (value) => isEmpty(value) || value >= 0 || 'Wartość musi być nieujemna',
  ],
  priceGroupRules: [
    (value) => !!value.toString() || 'Wartość jest wymagana',
    (value) => value >= 0 || 'Wartość musi być nieujemna',
    (value) => {
      const valueContainsComma = value.toString().includes('.');
      let spllitedValue = true;
      if (valueContainsComma) {
        spllitedValue = value.toString().split('.')[1]?.length <= 2;
      }
      return spllitedValue || 'Wprowadź prawidłowy format dziesiętny';
    },
  ],
  checkboxRequiredRules: [(value) => !!value || 'Pole jest wymagane'],
  fileRules: [(file) => !file || !(file.size > 5_297_152) || 'Plik przekracza dozwolony rozmiar'],
  phoneRules: [
    (value) => !!value || 'Pole jest wymagane',
    (value) => {
      let re = /^\s*\+?\s*([0-9][\s-]*){9,}$/;
      return re.test(value) || 'Numer telefonu jest nieprawidłowy';
    },
  ],
  chatRules: [
    (value) => value.length <= 100 || 'Za dużo znaków',
    (value) => value.length >= 1 || 'Za mało znaków',
  ],

  samePasswordRule: (password, value) => {
    return value === password || 'Podane hasła nie są takie same';
  },

  counter: (value) => value.length <= 50 || 'Za dużo znaków',
};
