<template>
  <v-container>
    <v-form v-model="isFormValid" ref="form">
      <v-row>
        <v-col class="ma-0 pa-0" cols="12" sm="6" md="6">
          <h3 class="text font-weight-bold mb-4">Obecne hasło*</h3>
          <v-text-field
            v-model="oldPassword"
            :append-icon="showOldPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showOldPassword = !showOldPassword"
            :type="showOldPassword ? 'text' : 'password'"
            name="oldPassword"
            outlined
            solo
            elevation="0"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="ma-0 pa-0" cols="12" sm="6" md="6">
          <h3 class="text font-weight-bold mb-4">Nowe hasło*</h3>
          <v-text-field
            v-model="newPassword"
            :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="validators.passwordRules"
            @click:append="showNewPassword = !showNewPassword"
            :type="showNewPassword ? 'text' : 'password'"
            name="newPassword"
            outlined
            solo
            elevation="0"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="ma-0 pa-0" cols="12" sm="6" md="6">
          <h3 class="text font-weight-bold mb-4">Powtórz hasło*</h3>
          <v-text-field
            v-model="confirmationPassword"
            :append-icon="showConfirmationPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showConfirmationPassword = !showConfirmationPassword"
            :rules="[
              ...validators.passwordRules,
              (value) => validators.samePasswordRule(value, newPassword),
            ]"
            :type="showConfirmationPassword ? 'text' : 'password'"
            name="confirmationPassword"
            outlined
            solo
            elevation="0"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="ma-0 pa-0" cols="12" sm="6" md="6">
          <primary-button :onClick="changePassword" :loading="loading"
            >Zapisz zmiany</primary-button
          >
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
import PrimaryButton from './buttons/PrimaryButton.vue';
import { validators } from '../validators/Validators';

export default {
  components: { PrimaryButton },
  name: 'ChangePassword',
  data() {
    return {
      loading: false,
      isFormValid: false,
      oldPassword: '',
      newPassword: '',
      confirmationPassword: '',
      showOldPassword: false,
      showNewPassword: false,
      showConfirmationPassword: false,
    };
  },
  methods: {
    async changePassword() {
      this.$refs.form.validate();
      if (this.isFormValid) {
        try {
          this.loading = true;
          await this.$store.dispatch('auth/changePassword', {
            newPassword: this.newPassword,
            oldPassword: this.oldPassword,
          });
          this.$toasted.global.success({
            message: 'Zmiana hasła przebiegła pomyślnie',
          });
          this.resetPasswords();
        } catch (err) {
          console.error(err);
        } finally {
          this.loading = false;
        }
      }
    },
    resetPasswords() {
      this.oldPassword = '';
      this.newPassword = '';
      this.confirmationPassword = '';
    },
  },
  computed: {
    validators() {
      return validators;
    },
  },
};
</script>
<style lang="scss" scoped></style>
