<template>
  <title-wrapper title="Edycja konta">
    <collapse title="Zmień numer telefonu">
      <edit-account-item
        title="Numer telefonu"
        label="Numer telefonu"
        :value="userData ? userData.phone : ''"
        v-on:click="changePhone"
        :loading="loadingPhone"
        :validators="validators.phoneRules"
      ></edit-account-item
    ></collapse>
    <collapse title="Zmień e-mail">
      <edit-account-item
        title="Adres e-mail"
        label="E-mail"
        :value="userData ? userData.email : ''"
        :loading="loadingEmail"
        v-on:click="changeEmail"
        :validators="validators.emailRules"
      ></edit-account-item
    ></collapse>
    <collapse title="Zmień hasło"> <change-password></change-password></collapse>
    <collapse title="Zarządzanie kontem"> <delete-account></delete-account></collapse>
  </title-wrapper>
</template>
<script>
import EditAccountItem from '../../components/EditAccountItem.vue';
import Collapse from '../../components/Collapse.vue';
import ChangePassword from '../../components/ChangePassword.vue';
import DeleteAccount from '../../components/DeleteAccount.vue';
import { validators } from '../../validators/Validators';
import { mapGetters } from 'vuex';
import TitleWrapper from '../../components/TitleWrapper.vue';

export default {
  name: 'EditAccount',
  components: { Collapse, EditAccountItem, ChangePassword, DeleteAccount, TitleWrapper },
  data() {
    return {
      loadingPhone: false,
      loadingEmail: false,
    };
  },
  methods: {
    async changeEmail(email) {
      try {
        this.loadingEmail = true;
        await this.$store.dispatch('auth/changeEmail', email);
        this.$toasted.global.success({
          message: 'Zmiana emaila przebiegła pomyślnie',
        });
      } catch (err) {
        console.error(err);
      } finally {
        this.loadingEmail = false;
      }
    },
    async changePhone(phone) {
      try {
        this.loadingPhone = true;
        await this.$store.dispatch('auth/changePhone', phone);
        this.$toasted.global.success({
          message: 'Zmiana telefonu przebiegła pomyślnie',
        });
      } catch (err) {
        console.error(err);
      } finally {
        this.loadingPhone = false;
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['userData']),
    validators() {
      return validators;
    },
  },
};
</script>
<style lang="scss" scoped></style>
