<template>
  <v-dialog
    transition="dialog-bottom-transition"
    persistent
    v-model="open"
    :max-width="modalWidth"
    scrollable
  >
    <v-card>
      <v-card-title class="text font-weight-bold"> {{ title }} </v-card-title>

      <v-card-text class="text-sm grey--text">
        <slot />
      </v-card-text>

      <v-container>
        <v-row justify="center" class="ma-4 mt-0">
          <v-btn
            elevation="0"
            block
            @click="accept"
            :loading="loading"
            :color="color ? color : 'primary'"
            class="pa-4 v-size--large white--text"
            x-large
            >{{ agree ? agree : 'Akceptuj' }}</v-btn
          >
        </v-row>
        <v-row justify="center" class="ma-4 mt-0" v-if="showOptionalAction">
          <v-btn
            elevation="0"
            block
            :loading="loading"
            color="primary"
            class="pa-4 mx-4 white--text"
            x-large
            @click="optionalAction"
          >
            {{ optionalActionLabel }}</v-btn
          >
        </v-row>
        <v-row justify="center" v-if="showDisagree">
          <v-btn class="mb-4 font-weight-medium border-bottom" color="font" text @click="decline">
            {{ disagree ? disagree : 'Anuluj' }}</v-btn
          >
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'ConfirmModal',
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Potwierdzenie',
    },
    agree: {
      type: String,
      default: 'Akceptuj',
    },
    disagree: {
      type: String,
      default: 'Anuluj',
    },
    color: {
      type: String,
      default: 'primary',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showDisagree: {
      type: Boolean,
      default: true,
    },
    showOptionalAction: {
      type: Boolean,
      default: false,
    },
    optionalActionLabel: {
      type: String,
      default: 'Opcjonalnie',
    },
    modalWidth: {
      type: String,
      default: '400',
    },
  },
  methods: {
    decline() {
      this.dialog = false;
      this.$emit('decline');
    },
    accept() {
      this.dialog = false;
      this.$emit('accept');
    },
    optionalAction() {
      this.dialog = false;
      this.$emit('optionalAction');
    },
  },
};
</script>
<style lang="scss" scoped>
.border-bottom:after {
  content: '\00a0';
  position: absolute;
  transform: translateY(600%);
  width: 55px;
  height: 2px;
  background: #333333;
}
</style>
